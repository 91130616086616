import { gql, TypedDocumentNode } from '@apollo/client';
import { salesBannerBlockFragment } from '@src/components/SalesBannerBlock/SalesBannerBlock.fragment';
import {
  CTAFragment,
  discoverBlockFragment,
  productPromotionBlockFragment,
  genericTextBlockFragment,
  heroLargeFragment,
  heroSmallFragment,
  hyperlinkFragment,
  mediaAssetFragment,
  mediaImageFragment,
  mediaVideoFragment,
  pageCommunityDetailHyperlinkFragment,
  pageCommunityDetailMetadataFragment,
  pageCommunityNewsHyperlinkFragment,
  pageCommunityNewsMetadataFragment,
  pageCommunityEventHyperlinkFragment,
  pageCommunityEventMetadataFragment,
  pageCommunityStandardHyperlinkFragment,
  pageCommunityStandardMetadataFragment,
  pageNationalNewsHyperlinkFragment,
  pageNationalNewsMetadataFragment,
  pageNationalStandardHyperlinkFragment,
  pageNationalStandardMetadataFragment,
  sectionTitleBlockFragment,
  sysFragment,
  alternatingTextBlockFragment,
  tileContainerBlockFragment,
  contentfulMetadataFragment,
  alternatingGalleryBlockFragment,
  galleryFragment,
  pageCommunityPropertyDetailHyperlinkFragment,
  pageCommunityPropertyDetailMetadataFragment,
  videoBlockFragment,
  matterportBlockFragment,
  twoColumnBlockFragment,
  staticBlockFragment,
  contentBlocksPart1CollectionNationalStandardFragment,
  contentBlocksPart2CollectionNationalStandardFragment,
  accordionBlockFragment
} from '@src/graphql/fragments';

/**
 * Alias used:
 *  - page : This is the page specific data
 *  - pagePath : The path of contentful pages to this path
 *     - Used for things like breadcrumbs or metadata og:url  etc.
 */
export const pageNationalStandardQuery = gql`
  query pageNationalStandard($slug: String, $preview: Boolean) {
    page: pageNationalStandardCollection(
      where: { slug: $slug }
      preview: $preview
      limit: 1
    ) {
      items {
        sys {
          publishedAt
          ...sys
        }
        slug
        title
        seoTitle
        seoDescription
        noIndex
        noFollow
        hero {
          ...heroSmall
          ...heroLarge
        }
        introText
        discoverBlock {
          ...discoverBlock
        }
        displayGlobalImpactPanel
        displayGlobalCtaPanel
        disclaimer {
          ...genericTextBlock
        }
      }
    }

    pagePath: pageNationalStandardCollection(
      where: { slug: $slug }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageNationalStandardHyperlink
      }
    }
  }

  ${sysFragment}
  ${hyperlinkFragment}
  ${contentfulMetadataFragment}

  ${mediaAssetFragment}
  ${mediaImageFragment}
  ${mediaVideoFragment}
  ${galleryFragment}

  ${genericTextBlockFragment}

  ${heroSmallFragment}
  ${heroLargeFragment}
  ${discoverBlockFragment}

  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityPropertyDetailMetadataFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
` as TypedDocumentNode;

/**
 * Alias used:
 *  - sectionContentBlocks : The contentBlocks for a page
 */
export const pageNationalStandardPart1ContentBlocksQuery = gql`
  query pageNationalStandardPart1ContentBlocks(
    $slug: String
    $preview: Boolean
  ) {
    pageContentBlocks: pageNationalStandardCollection(
      where: { slug: $slug }
      preview: $preview
      limit: 1
    ) {
      items {
        ...contentBlocksPart1CollectionNationalStandard
      }
    }
  }

  ${sysFragment}
  ${CTAFragment}
  ${hyperlinkFragment}
  ${contentfulMetadataFragment}

  ${contentBlocksPart1CollectionNationalStandardFragment}

  ${mediaAssetFragment}
  # TODO: Removing MediaImage for time being. We may bring it back - https://makernz.atlassian.net/browse/LL006-717
  # ${mediaImageFragment}
  ${mediaVideoFragment}
  ${galleryFragment}

  ${alternatingGalleryBlockFragment}
  ${alternatingTextBlockFragment}
  ${genericTextBlockFragment}
  ${matterportBlockFragment}
  ${productPromotionBlockFragment}
  ${sectionTitleBlockFragment}
  ${staticBlockFragment}
  ${tileContainerBlockFragment}

  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}

  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityPropertyDetailMetadataFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}

  ${pageCommunityDetailHyperlinkFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
` as TypedDocumentNode;

export const pageNationalStandardPart2ContentBlocksQuery = gql`
  query pageNationalStandardPart2ContentBlocks(
    $slug: String
    $preview: Boolean
  ) {
    pageContentBlocks: pageNationalStandardCollection(
      where: { slug: $slug }
      preview: $preview
      limit: 1
    ) {
      items {
        ...contentBlocksPart2CollectionNationalStandard
      }
    }
  }

  ${sysFragment}
  ${CTAFragment}
  ${hyperlinkFragment}
  ${contentfulMetadataFragment}

  ${contentBlocksPart2CollectionNationalStandardFragment}

  ${mediaAssetFragment}
  ${mediaVideoFragment}

  ${accordionBlockFragment}
  ${twoColumnBlockFragment}
  ${videoBlockFragment}
  ${salesBannerBlockFragment}

  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}

  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityPropertyDetailMetadataFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}

  ${pageCommunityDetailHyperlinkFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
` as TypedDocumentNode;
