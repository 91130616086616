import { gql } from '@apollo/client';
/**
 * "Page: National Standard" ContentBlocks on the PageNationalStandard content-type
 * NOTE: This is split into 2 part. THIS IS PART 1
 */
export const contentBlocksPart1CollectionNationalStandardFragment = gql`
  fragment contentBlocksPart1CollectionNationalStandard on PageNationalStandard {
    contentBlocksCollection(limit: 10) {
      items {
        ...alternatingGalleryBlock
        ...alternatingTextBlock
        ...genericTextBlock
        ...matterportBlock
        # TODO: Removing MediaImage for time being. We may bring it back - https://makernz.atlassian.net/browse/LL006-717
        # ...mediaImage
        ...productPromotionBlock
        ...sectionTitleBlock
        ...staticBlock
        ...tileContainerBlock
      }
    }
  }
`;
/**
 * "Page: National Standard" ContentBlocks on the PageNationalStandard content-type
 * NOTE: This is split into 2 part. THIS IS PART 2
 */
export const contentBlocksPart2CollectionNationalStandardFragment = gql`
  fragment contentBlocksPart2CollectionNationalStandard on PageNationalStandard {
    contentBlocksCollection(limit: 10) {
      items {
        ...accordionBlock
        ...twoColumnBlock
        ...videoBlock
        ...salesBannerBlock
      }
    }
  }
`;

/**
 * "Page: Community Standard" ContentBlocks on the PageCommunityStandard content-type
 * NOTE: This is split into 2 part. THIS IS PART 1
 */
export const contentBlocksPart1CollectionCommunityStandardFragment = gql`
  fragment contentBlocksPart1CollectionCommunityStandard on PageCommunityStandard {
    contentBlocksCollection(limit: 10) {
      items {
        ...alternatingGalleryBlock
        ...alternatingTextBlock
        ...communityContactUsBlock
        ...genericTextBlock
        ...matterportBlock
        # TODO: Removing MediaImage for time being. We may bring it back - https://makernz.atlassian.net/browse/LL006-717
        # ...mediaImage
        ...productPromotionBlock
        ...sectionTitleBlock
        ...staticBlock
        ...tileContainerBlock
      }
    }
  }
`;

/**
 * "Page: Community Standard" ContentBlocks on the PageCommunityStandard content-type
 * NOTE: This is split into 2 part. THIS IS PART 2
 */
export const contentBlocksPart2CollectionCommunityStandardFragment = gql`
  fragment contentBlocksPart2CollectionCommunityStandard on PageCommunityStandard {
    contentBlocksCollection(limit: 10) {
      items {
        ...accordionBlock
        ...twoColumnBlock
        ...videoBlock
      }
    }
  }
`;

/**
 * "Page: Community Home" ContentBlocks on the PageCommunityDetail content-type
 * NOTE: there is no split fragment for contentBlocksCollectionCommunityDetail
 */
export const contentBlocksCollectionCommunityDetailFragment = gql`
  fragment contentBlocksPart1CollectionCommunityDetail on PageCommunityDetail {
    contentBlocksCollection(limit: 10) {
      items {
        ...alternatingGalleryBlock
        ...alternatingTextBlock
        ...matterportBlock
        ...productPromotionBlock
        ...sectionTitleBlock
        ...videoBlock
        # TODO: Removing MediaImage for time being. We may bring it back - https://makernz.atlassian.net/browse/LL006-717
        # ...mediaImage
        ...twoColumnBlock
      }
    }
  }
`;
